
import Vue from "vue";
import store from "@/store";
import LangSwitcher from "@/components/settings/LangSwitcher.vue";
import rules from "@/services/helpers/validation-rules";

export default Vue.extend({
  name: "Login",

  components: { LangSwitcher },

  data: () => ({
    //todo
    logo: require("@/assets/images/logos/logo_ru.png") as any,
    rules,
    model: {
      email: "" as string,
      password: "" as string
    },
    errorMessage: "" as string,
    showPassword: false as boolean,
    forgot: false as boolean,
    loading: false as boolean
  }),
  beforeRouteEnter(from, to, next) {
    const hasCredentials = store.getters["authentication/hasAccessToken"];

    if (hasCredentials) {
      next("/");
    } else {
      next();
    }
  },
  computed: {
    PasswordType(): string {
      if (this.showPassword) {
        return "text";
      }
      return "password";
    }
  },

  mounted() {
    const lang = this.$store.getters["localization/getCurrent"];
    this.logo = require(`@/assets/images/logos/logo_${lang}.png`);

    // if (process.env.VUE_APP_DEBUG) {
    //   this.model = { email: "root@domain.com", password: "123456789" };
    // }
  },

  methods: {
    async signIn(): Promise<void> {
      this.loading = true;
      try {
        if (await (this.$refs.form as Vue).validate()) {
          const response = await this.$API.auth().login(this.model);

          await this.$store.dispatch("authentication/login", response);
          await this.$store.dispatch("user/set", (response as any).user);
          document.location.href = "/";
        }
      } catch (e) {
        this.errorMessage = e.message;
      }
      this.loading = false;
    },
    async forgotPassword(): Promise<void> {
      this.loading = true;
      this.errorMessage = "";
      try {
        await this.$API
          .auth()
          .forgot({ email: this.model.email, is_admin: true });

        await this.$router.push("/auth/success-reset");
      } catch (e) {
        this.errorMessage = e.message;
        await this.$store.dispatch("alert/showError", e.message);
      }
      this.loading = false;
    },
    togglePasswordType(): void {
      this.showPassword = !this.showPassword;
      this.errorMessage = "";
    },
    toggleForgot(): void {
      this.forgot = !this.forgot;
    },
    submitForm(): void {
      if (this.forgot) {
        this.forgotPassword();
      } else {
        this.signIn();
      }
    }
  }
});
